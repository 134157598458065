import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full'
  },
  
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then( m => m.IntroPageModule)
  },

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },

  {
    path: 'sesion',
    loadChildren: () => import('./sesion/sesion.module').then( m => m.SesionPageModule)
  },

  {
    path: 'acerca',
    loadChildren: () => import('./acerca/acerca.module').then( m => m.AcercaPageModule)
  },

  {
    path: 'indice',
    loadChildren: () => import('./indice/indice.module').then( m => m.IndicePageModule)
  },

  {
    path: 'mapagral',
    loadChildren: () => import('./mapagral/mapagral.module').then( m => m.MapagralPageModule)
  },

  {
    path: 'enlaces',
    loadChildren: () => import('./enlaces/enlaces.module').then( m => m.EnlacesPageModule)
  },

  {
    path: 'glosario',
    loadChildren: () => import('./glosario/glosario.module').then( m => m.GlosarioPageModule)
  },

  {
    path: 'biblio',
    loadChildren: () => import('./biblio/biblio.module').then( m => m.BiblioPageModule)
  },

  {
    path: 'biblio-articulo/:id',
    loadChildren: () => import('./biblio-articulo/biblio-articulo.module').then( m => m.BiblioArticuloPageModule)
  },

  {
    path: 'proyecto',
    loadChildren: () => import('./proyecto/proyecto.module').then( m => m.ProyectoPageModule)
  },

  {
    path: 'public-congress',
    loadChildren: () => import('./public-congress/public-congress.module').then( m => m.PublicCongressPageModule)
  },

  {
    path: 'public-congress-articulo/:id',
    loadChildren: () => import('./public-congress-articulo/public-congress-articulo.module').then( m => m.PublicCongressArticuloPageModule)
  },
  
  {
    path: 'intro-problema',
    loadChildren: () => import('./intro-problema/intro-problema.module').then( m => m.IntroProblemaPageModule)
  },
  
  {
    path: 'conclusiones',
    loadChildren: () => import('./conclusiones/conclusiones.module').then( m => m.ConclusionesPageModule)
  },

  {
    path: 'discusiones',
    loadChildren: () => import('./discusiones/discusiones.module').then( m => m.DiscusionesPageModule)
  },

  {
    path: 'marco-teorico',
    loadChildren: () => import('./marco-teorico/marco-teorico.module').then( m => m.MarcoTeoricoPageModule)
  },

  {
    path: 'metodologia-tesis',
    loadChildren: () => import('./metodologia-tesis/metodologia-tesis.module').then( m => m.MetodologiaTesisPageModule)
  },

  {
    path: 'exploracion-digital',
    loadChildren: () => import('./metod/exploracion-digital/exploracion-digital.module').then( m => m.ExploracionDigitalPageModule)
  },
  {
    path: 'eventos-exposiciones',
    loadChildren: () => import('./metod/eventos-exposiciones/eventos-exposiciones.module').then( m => m.EventosExposicionesPageModule)
  },

  {
    path: 'estrategia',
    loadChildren: () => import('./metod/estrategia/estrategia.module').then( m => m.EstrategiaPageModule)
  },
  {
    path: 'enfoque',
    loadChildren: () => import('./metod/enfoque/enfoque.module').then( m => m.EnfoquePageModule)
  },
  {
    path: 'procedimiento',
    loadChildren: () => import('./metod/procedimiento/procedimiento.module').then( m => m.ProcedimientoPageModule)
  },
  {
    path: 'protocolo',
    loadChildren: () => import('./metod/protocolo/protocolo.module').then( m => m.ProtocoloPageModule)
  },

  {
    path: 'resultados',
    loadChildren: () => import('./resultados/resultados.module').then( m => m.ResultadosPageModule)
  },

  {
    path: 'edps',
    loadChildren: () => import('./result/edps/edps.module').then( m => m.EdpsPageModule)
  },
  {
    path: 'tndi',
    loadChildren: () => import('./result/tndi/tndi.module').then( m => m.TndiPageModule)
  },
  {
    path: 'tdh',
    loadChildren: () => import('./result/tdh/tdh.module').then( m => m.TdhPageModule)
  },
  {
    path: 'exploracion-digital-tndi',
    loadChildren: () => import('./result/exploracion-digital-tndi/exploracion-digital-tndi.module').then( m => m.ExploracionDigitalTndiPageModule)
  },
  {
    path: 'plataformas-tndi-internac',
    loadChildren: () => import('./result/plataformas-tndi-internac/plataformas-tndi-internac.module').then( m => m.PlataformasTndiInternacPageModule)
  },
  {
    path: 'plataformas-tndi-nacional',
    loadChildren: () => import('./result/plataformas-tndi-nacional/plataformas-tndi-nacional.module').then( m => m.PlataformasTndiNacionalPageModule)
  },
  {
    path: 'rrss-tndi-nacional',
    loadChildren: () => import('./result/rrss-tndi-nacional/rrss-tndi-nacional.module').then( m => m.RrssTndiNacionalPageModule)
  },
  {
    path: 'rrss-tndi-internac',
    loadChildren: () => import('./result/rrss-tndi-internac/rrss-tndi-internac.module').then( m => m.RrssTndiInternacPageModule)
  },
  
  {
    path: 'plataformas-tdh',
    loadChildren: () => import('./result/plataformas-tdh/plataformas-tdh.module').then( m => m.PlataformasTdhPageModule)
  },
  {
    path: 'rrss-tdh',
    loadChildren: () => import('./result/rrss-tdh/rrss-tdh.module').then( m => m.RrssTdhPageModule)
  },
  {
    path: 'casos-geoencuesta',
    loadChildren: () => import('./result/casos-geoencuesta/casos-geoencuesta.module').then( m => m.CasosGeoencuestaPageModule)
  },

  {
    path: 'casos-estudio',
    loadChildren: () => import('./casos-estudio/casos-estudio.module').then( m => m.CasosEstudioPageModule)
  },

  {
    path: 'casos-estudio-articulo/:id',
    loadChildren: () => import('./casos-estudio-articulo/casos-estudio-articulo.module').then( m => m.CasosEstudioArticuloPageModule)
  },
  {
    path: 'art-geoencuesta/:id',
    loadChildren: () => import('./result/art-geoencuesta/art-geoencuesta.module').then( m => m.ArtGeoencuestaPageModule)
  },
  {
    path: 'art-platf-tndi-int/:id',
    loadChildren: () => import('./result/art-platf-tndi-int/art-platf-tndi-int.module').then( m => m.ArtPlatfTndiIntPageModule)
  },
  {
    path: 'art-platf-tndi-nac/:id',
    loadChildren: () => import('./result/art-platf-tndi-nac/art-platf-tndi-nac.module').then( m => m.ArtPlatfTndiNacPageModule)
  },
 
  {
    path: 'art-rrss-tndi-int/:id',
    loadChildren: () => import('./result/art-rrss-tndi-int/art-rrss-tndi-int.module').then( m => m.ArtRrssTndiIntPageModule)
  },
  {
    path: 'art-rrss-tdh/:id',
    loadChildren: () => import('./result/art-rrss-tdh/art-rrss-tdh.module').then( m => m.ArtRrssTdhPageModule)
  },
  {
    path: 'art-platf-tdh/:id',
    loadChildren: () => import('./result/art-platf-tdh/art-platf-tdh.module').then( m => m.ArtPlatfTdhPageModule)
  },

  {
    path: 'art-rrss-tdh-gubernm/:id',
    loadChildren: () => import('./result/art-rrss-tdh-gubernm/art-rrss-tdh-gubernm.module').then( m => m.ArtRrssTdhGubernmPageModule)
  },
  {
    path: 'art-rrss-tdh-corpor/:id',
    loadChildren: () => import('./result/art-rrss-tdh-corpor/art-rrss-tdh-corpor.module').then( m => m.ArtRrssTdhCorporPageModule)
  },
  {
    path: 'art-rrss-tdh-academ/:id',
    loadChildren: () => import('./result/art-rrss-tdh-academ/art-rrss-tdh-academ.module').then( m => m.ArtRrssTdhAcademPageModule)
  },
  {
    path: 'art-rrss-tndi-nac-ini/:id',
    loadChildren: () => import('./result/art-rrss-tndi-nac-ini/art-rrss-tndi-nac-ini.module').then( m => m.ArtRrssTndiNacIniPageModule)
  },
  {
    path: 'art-rrss-tndi-nac-ava/:id',
    loadChildren: () => import('./result/art-rrss-tndi-nac-ava/art-rrss-tndi-nac-ava.module').then( m => m.ArtRrssTndiNacAvaPageModule)
  },
  {
    path: 'sitios-tndi-internac',
    loadChildren: () => import('./result/sitios-tndi-internac/sitios-tndi-internac.module').then( m => m.SitiosTndiInternacPageModule)
  },
  {
    path: 'sitios-tndi-nacional',
    loadChildren: () => import('./result/sitios-tndi-nacional/sitios-tndi-nacional.module').then( m => m.SitiosTndiNacionalPageModule)
  },
  
  {
    path: 'art-sitios-tndi-int/:id',
    loadChildren: () => import('./result/art-sitios-tndi-int/art-sitios-tndi-int.module').then( m => m.ArtSitiosTndiIntPageModule)
  },
  {
    path: 'art-sitios-tndi-nac/:id',
    loadChildren: () => import('./result/art-sitios-tndi-nac/art-sitios-tndi-nac.module').then( m => m.ArtSitiosTndiNacPageModule)
  },
  {
    path: 'sitios-tdh',
    loadChildren: () => import('./result/sitios-tdh/sitios-tdh.module').then( m => m.SitiosTdhPageModule)
  },
  {
    path: 'art-sitios-tdh/:id',
    loadChildren: () => import('./result/art-sitios-tdh/art-sitios-tdh.module').then( m => m.ArtSitiosTdhPageModule)
  },
  {
    path: 'art-sitios-tdh-academ/:id',
    loadChildren: () => import('./result/art-sitios-tdh-academ/art-sitios-tdh-academ.module').then( m => m.ArtSitiosTdhAcademPageModule)
  },
  {
    path: 'art-sitios-tdh-corpor/:id',
    loadChildren: () => import('./result/art-sitios-tdh-corpor/art-sitios-tdh-corpor.module').then( m => m.ArtSitiosTdhCorporPageModule)
  },
  {
    path: 'art-sitios-tdh-gubernm/:id',
    loadChildren: () => import('./result/art-sitios-tdh-gubernm/art-sitios-tdh-gubernm.module').then( m => m.ArtSitiosTdhGubernmPageModule)
  },
  {
    path: 'mapa-plataformas-tndi-int',
    loadChildren: () => import('./result/mapa-plataformas-tndi-int/mapa-plataformas-tndi-int.module').then( m => m.MapaPlataformasTndiIntPageModule)
  },
  {
    path: 'mapa-plataformas-tndi-nac',
    loadChildren: () => import('./result/mapa-plataformas-tndi-nac/mapa-plataformas-tndi-nac.module').then( m => m.MapaPlataformasTndiNacPageModule)
  },
  {
    path: 'anexos',
    loadChildren: () => import('./anexos/anexos.module').then( m => m.AnexosPageModule)
  },
  {
    path: 'mapa-rrss-tndi-int',
    loadChildren: () => import('./result/mapa-rrss-tndi-int/mapa-rrss-tndi-int.module').then( m => m.MapaRrssTndiIntPageModule)
  },
  {
    path: 'mapa-rrss-tndi-nac',
    loadChildren: () => import('./result/mapa-rrss-tndi-nac/mapa-rrss-tndi-nac.module').then( m => m.MapaRrssTndiNacPageModule)
  },
  {
    path: 'mapa-sitios-tndi-nac',
    loadChildren: () => import('./result/mapa-sitios-tndi-nac/mapa-sitios-tndi-nac.module').then( m => m.MapaSitiosTndiNacPageModule)
  },
  {
    path: 'mapa-sitios-tndi-int',
    loadChildren: () => import('./result/mapa-sitios-tndi-int/mapa-sitios-tndi-int.module').then( m => m.MapaSitiosTndiIntPageModule)
  },
  {
    path: 'platf-tdh-int-corpor',
    loadChildren: () => import('./result/platf-tdh-int-corpor/platf-tdh-int-corpor.module').then( m => m.PlatfTdhIntCorporPageModule)
  },
  {
    path: 'platf-tdh-int-gubernm',
    loadChildren: () => import('./result/platf-tdh-int-gubernm/platf-tdh-int-gubernm.module').then( m => m.PlatfTdhIntGubernmPageModule)
  },
  {
    path: 'platf-tdh-int-academ',
    loadChildren: () => import('./result/platf-tdh-int-academ/platf-tdh-int-academ.module').then( m => m.PlatfTdhIntAcademPageModule)
  },
  {
    path: 'platf-tdh-nac-corpor',
    loadChildren: () => import('./result/platf-tdh-nac-corpor/platf-tdh-nac-corpor.module').then( m => m.PlatfTdhNacCorporPageModule)
  },
  {
    path: 'platf-tdh-nac-gubernm',
    loadChildren: () => import('./result/platf-tdh-nac-gubernm/platf-tdh-nac-gubernm.module').then( m => m.PlatfTdhNacGubernmPageModule)
  },
  {
    path: 'platf-tdh-nac-academ',
    loadChildren: () => import('./result/platf-tdh-nac-academ/platf-tdh-nac-academ.module').then( m => m.PlatfTdhNacAcademPageModule)
  },
  {
    path: 'art-platf-tdh-int-gob/:id',
    loadChildren: () => import('./result/art-platf-tdh-int-gob/art-platf-tdh-int-gob.module').then( m => m.ArtPlatfTdhIntGobPageModule)
  },
  {
    path: 'art-platf-tdh-int-corp/:id',
    loadChildren: () => import('./result/art-platf-tdh-int-corp/art-platf-tdh-int-corp.module').then( m => m.ArtPlatfTdhIntCorpPageModule)
  },
  {
    path: 'art-platf-tdh-int-acad/:id',
    loadChildren: () => import('./result/art-platf-tdh-int-acad/art-platf-tdh-int-acad.module').then( m => m.ArtPlatfTdhIntAcadPageModule)
  },
  {
    path: 'art-platf-tdh-nac-acad/:id',
    loadChildren: () => import('./result/art-platf-tdh-nac-acad/art-platf-tdh-nac-acad.module').then( m => m.ArtPlatfTdhNacAcadPageModule)
  },
  {
    path: 'art-platf-tdh-nac-corp/:id',
    loadChildren: () => import('./result/art-platf-tdh-nac-corp/art-platf-tdh-nac-corp.module').then( m => m.ArtPlatfTdhNacCorpPageModule)
  },
  {
    path: 'art-platf-tdh-nac-gob/:id',
    loadChildren: () => import('./result/art-platf-tdh-nac-gob/art-platf-tdh-nac-gob.module').then( m => m.ArtPlatfTdhNacGobPageModule)
  }
  
  
  
  

  

  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
